import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import loadable from '@loadable/component'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'

const AdvertiseWithUs = ({ location }) => {
    const [component, setcomponent] = useState()
    const [link, setlink] = useState("")

    const handleClick = () => {
        setlink("https://www.youtube.com/embed/qPUI4H0nCLo")
    }

    const images = [
        'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/adv-display1.webp',
        'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/adv-display2.webp',
        'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/adv-display3.webp',
        'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/adv-display4.webp'
    ]

    const checkWidth = () => {
        if(window !== 'undefined'){
            if(window.innerWidth <= 1024){             
                const EventImages= loadable(() => import('../components/Testimonials/AdvertiseCarousel'))
                setcomponent(<EventImages />)
            }
            else{
                const Theatre= loadable(() => import('../components/TheatreCarousel'))
                setcomponent(<Theatre  content={images}/>)
            }
        }
    }

    useEffect(() => {
        checkWidth()
    }, [])
    

  return (
    <Layout location={location}>
        <section className='mb-28 px-5 pt-10 xl:pt-16 grid place-content-center w-11/12 lg:max-w-7xl bg-gray-newGrayDark mx-auto rounded-lg'>
            <div className="my-2 grid justify-center items-center">
                <h2 className="text-center heroTagStyle">ADVERTISE</h2>
                <h1 class="xl:w-full homepage-heading text-center relative">Increase your brand visibilty and sales</h1>
                <p className="mt-4 mb-8 xl:mt-6 xl:mb-8 text-gray-500 text-center w-full text-black max-w-3xl mx-auto" style= {{ lineHeight: '2rem' }}>
                    Advertise on Vantage Circle and reach out to the largest corporate audience across the globe in an organic and compelling way.
                </p>
                <a className="vc-new-orange-btn-rounded-full mx-auto text-white lato rounded-full mb-3 xl:mb-0" href="#form">Explore now</a>
            </div>
            <div className='flex justify-center mt-10'>
                <picture>
                    <source className='z-10 relative transform translate-y-5 xl:translate-y-10' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1300/q_auto/gatsbycms/uploads/2024/02/adv-Hero-Image-1.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="1000" />
                    <img className='z-10 relative transform translate-y-5 xl:translate-y-10' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/02/adv-Hero-Image-1.webp" alt="Vantage Perks" width="400" />
                </picture>
            </div>
        </section>

        <section className='pb-10 xl:py-16 w-full'>
            <div className='py-12 lg:py-5 lg:flex justify-around items-center w-11/12 lg:max-w-7xl mx-auto bg-gray-newGrayDark p-5 xl:pr-3 rounded-lg'>
                <div className='w-full lg:w-1/2 lg:p-5 lg:pl-12'>
                    <h2 className='homepage-div-heading text-center lg:text-left'>Why partner with us?</h2>
                    <p className='mt-5 text-gray-500 text-center lg:text-left'>Our team works closely with you to create advertising packages as unique as your business.
    We help you reach the right audience in a way that gets them to take action.</p>
                    <p className='mt-5 text-gray-500 text-center lg:text-left'>Watch the video to see how it works</p>
                </div>
                <div className='w-full lg:w-7/12 lg:pl-7 lg:pr-2 mt-7 lg:mt-0'>
                    <div className='rounded-xl overflow-hidden cursor-pointer relative'>
                        <img className = {link.length ? 'hidden' : 'block'} onClick={handleClick} src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/01/career-page-vid-thumb-1.webp" width= "768px"/>
                        <div className=''><iframe className={link.length ? 'block' : 'hidden'} src={link} style= {{ maxWidth: '100%', maxHeight: '100%' }} width= "768" height="360" title="What's it like to Work at Vantage Circle ? | Corporate Culture | Careers at Vantage Circle" allowfullscreen /></div>
                    </div>
                </div>
            </div>
        </section>

        <section className='grid place-content-center py-10 xl:py-16 w-full lg:px-5'>
            <div className='w-11/12 lg:max-w-7xl mx-auto'>
                <h2 className='homepage-section-heading text-center max-w-5xl mx-auto px-2'>Get a demo to see how you can maximize brand awareness with Vantage Perks</h2>
                <div className='bg-gray-newGrayDark p-3 pt-3 pb-12 lg:p-7 mt-10 lg:mt-20 grid grid-cols-1 lg:grid-cols-2 justify-between rounded-xl md:w-auto mx-auto'>
                    <div className='w-full'>
                        <ul class="flex flex-col w-full md:pl-8 xl:p-3 lg:pl-0 mb-6 mt-8 lg:mb-0 lg:mt-6">
                            <li class="flex w-full py-2 md:py-4 lg:py-5 xl:py-3 ml-1">
                                <div class="w-1/12"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20"/></div>
                                <div class="w-10/12 transform -translate-y-1 lg:-translate-x-2">
                                    <h2 class="pl-2 lg:pl-0 m-0 text-gray-500 homepage-mini-heading font-bold">Maximize Your Brand Awareness</h2>
                                    <p className='pl-2 lg:pl-0 mt-2 text-gray-500'>Position yourself as the market leader, and ensure high visibility and maximum exposure for your company branding.</p>
                                </div>
                            </li>
                            <li class="flex w-full py-2 md:py-4 lg:py-5 xl:py-3 ml-1">
                                <div class="w-1/12"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20"/></div>
                                <div class="w-10/12 transform -translate-y-1 lg:-translate-x-2">
                                    <h2 class="pl-2 lg:pl-0 m-0 text-gray-500 homepage-mini-heading font-bold">Reach 1 Million+ Corporate Audience</h2>
                                    <p className='pl-2 lg:pl-0 mt-2 text-gray-500'>Position yourself as the market leader, and ensure high visibility and maximum exposure for your company branding.</p>
                                </div>
                            </li>
                            <li class="flex w-full py-2 md:py-4 lg:py-5 xl:py-3 ml-1">
                                <div class="w-1/12"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20"/></div>
                                <div class="w-10/12 transform -translate-y-1 lg:-translate-x-2">
                                    <h2 class="pl-2 lg:pl-0 m-0 text-gray-500 homepage-mini-heading font-bold">Promote Your Offers</h2>
                                    <p className='pl-2 lg:pl-0 mt-2 text-gray-500'>Position yourself as the market leader, and ensure high visibility and maximum exposure for your company branding.</p>
                                </div>
                            </li>
                            <li class="flex w-full py-2 md:py-4 lg:py-5 xl:py-3 ml-1">
                                <div class="w-1/12"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20"/></div>
                                <div class="w-10/12 transform -translate-y-1 lg:-translate-x-2">
                                    <h2 class="pl-2 lg:pl-0 m-0 text-gray-500 homepage-mini-heading font-bold">Generate Sales</h2>
                                    <p className='pl-2 lg:pl-0 mt-2 text-gray-500'>Position yourself as the market leader, and ensure high visibility and maximum exposure for your company branding.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='w-full md:w-11/12 md:mx-auto lg:mx-0 lg:justify-self-end md:pb-10 lg:pb-0 lg:p-2 darkText'>
                        <div class="card-1 rnrForm w-full flex flex-col place-items-center px-4 lg:px-6 py-7 lg:py-10 bg-white rounded-xl shadow lighterDesignForm h-full" id="form">
                            {/* <h2 class="text-2xl lato text-gray-500 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step away from making your employees happy</h2> */}
                            <div class="w-full flex justify-center items-center lato rewards team lg:pl-3">
                                <div class="flex justify-center items-center">
                                    <HubspotForm
                                    portalId="6153052"
                                    formId="e8480270-dcf2-4abe-a7a7-222bf2288c9e"
                                    onSubmit={() => console.log("Submit!")}
                                    onReady={form => console.log("Form ready!")}
                                    loading={<div>Loading...</div>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='py-10 xl:py-16 w-full px-5'>
            <div className='max-w-5xl mx-auto '>
                <h2 className='homepage-section-heading max-w-3xl text-center mx-auto mb-10 lg:mb-16'>We are proud to be associated with these leading brands!</h2>
                <picture>
                    <source className='z-10 relative mx-auto' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1400/q_auto/gatsbycms/uploads/2024/02/adv-Partners-logo-Image-1.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="1000" />
                    <img className='z-10 relative mx-auto' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/02/adv-Partners-logo-Image-1.webp" alt="Vantage Perks" width="400" />
                </picture>
                <div class="lg:flex pt-12 xl:pt-16 xl:items-center"><div class="lg:w-2/12">
                    <picture>
                        <source class="mx-auto pb-0 transform scale-75 xl:scale-90 transform -translate-y-4" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2024/02/adv-testimony-1.webp" media="(min-width: 640px)" type="image/webp" alt="client strip" width="179" height="179" />
                        <img class="mx-auto pb-0 transform scale-75 xl:scale-90 transform -translate-y-4" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_230/q_auto/gatsbycms/uploads/2024/02/adv-testimony-1.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_230/q_auto/gatsbycms/uploads/2024/02/adv-testimony-1.webp" alt="client-strip" width="200" height="200" />
                    </picture>
                    <p class="m-0 pb-1 block lg:hidden text-center font-bold">Sameer Gadgil</p>
                    <p className='mb-6 block lg:hidden text-center'>Vice President &amp; HR Head, Wipro</p>
                </div>
                    <div class="md:w-9/12 mx-auto lg:mx-0 xl:w-10/12 lg:ml-8">
                    <p class="text-gray-200 italic">"At EarlySalary, we are looking to help young salaried professionals every time they fall short of cash. Our partnership with Vantage Circle has been instrumental in helping us reach 1 lakh loans and is one of the top partners for EarlySalary. Vantage Circle has helped us reach out and engage with a large corporate audience."</p>
                    <p class="mt-3 hidden lg:block"><span class="font-bold">Akshay Mehrotra</span>, Co-Founder & CEO, EarlySalary.com</p>
                    </div>
                </div>
            </div>
        </section>

        <section className='w-full flex place-content-center py-10 xl:py-16 lg:px-5'>
            <div className='w-11/12 2xl:max-w-7xl mx-auto bg-gray-newGrayDark rounded-xl p-6 grid place-content-center relative overflow-hidden'>
                <h2 className='pt-5 pb-8 lg:py-10 homepage-section-heading text-center mx-auto'>A marketplace that big!</h2>
                <div className='max-w-7xl grid grid-cols-2 lg:grid-cols-4 gap-4 gap-y-10 md:gap-12 lg:gap-5 place-content-center pt-5 lg:mt-10 pb-2 lg:pb-0'>
                    <div class="rounded-xl bg-gray-100 border border-white shadow-xl p-5 py-9 relative">
                        <div className='w-full absolute -top-7 left-0 z-10 flex justify-center'>
                            <picture class="">
                                <source class="" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_92/q_auto/gatsbycms/uploads/2024/02/brand-partners-1.webp" media="(min-width: 640px)" type="image/webp" alt="Vantage Rewards" width="70" height="70" />
                                <img class="" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//w_44/q_auto/gatsbycms/uploads/2024/02/brand-partners-1.webp" alt="Vantage Rewards" width="50" height="50" />
                            </picture>
                        </div>
                        <h3 class="pb-2 mt-4 text-center homepage-section-heading text-orange">1.6M+</h3>
                        <p class="text-base m-0 text-center text-gray-500">Active users</p>
                    </div>
                    <div class="rounded-xl bg-gray-100 border border-white shadow-xl p-5 py-9 relative">
                        <div className='w-full absolute -top-7 left-0 z-10 flex justify-center'>
                            <picture class="">
                                <source class="" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_92/q_auto/gatsbycms/uploads/2024/02/brand-partners-1.webp" media="(min-width: 640px)" type="image/webp" alt="Vantage Rewards" width="70" height="70" />
                                <img class="" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//w_44/q_auto/gatsbycms/uploads/2024/02/brand-partners-1.webp" alt="Vantage Rewards" width="50" height="50" />
                            </picture>
                        </div>
                        <h3 class="pb-2 mt-4 text-center homepage-section-heading text-orange">3M+</h3>
                        <p class="text-base m-0 text-center text-gray-500">Monthly traffic</p>
                    </div>
                    <div class="rounded-xl bg-gray-100 border border-white shadow-xl p-5 py-9 relative lg:mt-0">
                        <div className='w-full absolute -top-7 left-0 z-10 flex justify-center'>
                            <picture class="">
                                <source class="" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_92/q_auto/gatsbycms/uploads/2024/02/brand-partners-1.webp" media="(min-width: 640px)" type="image/webp" alt="Vantage Rewards" width="70" height="70" />
                                <img class="" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//w_44/q_auto/gatsbycms/uploads/2024/02/brand-partners-1.webp" alt="Vantage Rewards" width="50" height="50" />
                            </picture>
                        </div>
                        <h3 class="pb-2 mt-4 text-center homepage-section-heading text-orange">5K+</h3>
                        <p class="text-base m-0 text-center text-gray-500">Brand partners</p>
                    </div>
                    <div class="rounded-xl bg-gray-100 border border-white shadow-xl p-5 py-9 relative lg:mt-0">
                        <div className='w-full absolute -top-7 left-0 z-10 flex justify-center'>
                            <picture class="">
                                <source class="" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_92/q_auto/gatsbycms/uploads/2024/02/brand-partners-1.webp" media="(min-width: 640px)" type="image/webp" alt="Vantage Rewards" width="70" height="70" />
                                <img class="" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//w_44/q_auto/gatsbycms/uploads/2024/02/brand-partners-1.webp" alt="Vantage Rewards" width="50" height="50" />
                            </picture>
                        </div>
                        <h3 class="pb-2 mt-4 text-center homepage-section-heading text-orange">185K+</h3>
                        <p class="text-base m-0 text-center text-gray-500">App download</p>
                    </div>
                </div>
                <div
                    className="w-56 h-56 hidden lg:block absolute bottom-1/4 transform rotate-90 -left-28 border rounded-full z-0"
                    style={{
                        borderWidth: "32px",
                        background: "transparent",
                        borderColor: "#8b8bc0",
                        // clipPath: "polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)",
                    }}
                ></div>
                <div
                    className="w-64 h-64 hidden lg:block absolute -top-1/4 transform -rotate-180 -right-10 border rounded-full z-0"
                    style={{
                        borderWidth: "32px",
                        background: "transparent",
                        borderColor: "#8b8bc0",
                        // clipPath: "polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)",
                    }}
                ></div>
            </div>
        </section>

        <section className='py-10 xl:py-16'>
            <div className='max-w-7xl mx-auto'>
                <h2 className='pt-5 lg:pt-8 lg:pb-7 homepage-section-heading text-center mx-auto px-5'>Display your ads everywhere, anywhere.</h2>
                <p className="my-3 lg:mt-0 text-gray-500 text-center w-full text-black max-w-3xl mx-auto px-5" style= {{ lineHeight: '2rem' }}>
                    Advertise on Vantage Circle and reach out to the largest corporate audience across the globe in an organic and compelling way.
                </p>
                {component}
            </div>
        
        </section>
    </Layout>
  )
}

export default AdvertiseWithUs